import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [0,3,4,2];

export const dictionary = {
		"/": [5],
		"/admin": [8,[3]],
		"/admin/add-new": [~30,[3]],
		"/admin/[cinemaSlug]": [~9,[3]],
		"/admin/[cinemaSlug]/(analytics)/general": [~10,[3]],
		"/admin/[cinemaSlug]/(platform)/gigs": [~18,[3]],
		"/admin/[cinemaSlug]/(platform)/gigs/[gigId]": [~19,[3]],
		"/admin/[cinemaSlug]/(manage)/halls": [~11,[3]],
		"/admin/[cinemaSlug]/(manage)/halls/[hallId]": [~12,[3]],
		"/admin/[cinemaSlug]/(platform)/movies": [~20,[3]],
		"/admin/[cinemaSlug]/(platform)/movies/[movieId]": [~21,[3]],
		"/admin/[cinemaSlug]/(sell)/payments": [~24,[3]],
		"/admin/[cinemaSlug]/(sell)/sell-ticket": [~25,[3]],
		"/admin/[cinemaSlug]/(sell)/sell-ticket/[showId]": [~26,[3]],
		"/admin/[cinemaSlug]/(sell)/sell-ticket/[showId]/sell": [~27,[3]],
		"/admin/[cinemaSlug]/(sell)/sell-ticket/[showId]/withdrawn": [~28,[3]],
		"/admin/[cinemaSlug]/(manage)/shows": [~13,[3]],
		"/admin/[cinemaSlug]/(manage)/shows/create": [~15,[3]],
		"/admin/[cinemaSlug]/(manage)/shows/[showId]": [~14,[3]],
		"/admin/[cinemaSlug]/(platform)/sports": [~22,[3]],
		"/admin/[cinemaSlug]/(platform)/sports/[sportId]": [~23,[3]],
		"/admin/[cinemaSlug]/(manage)/ticket-prices": [~16,[3]],
		"/admin/[cinemaSlug]/(manage)/ticket-prices/[ticketPriceId]": [~17,[3]],
		"/admin/[cinemaSlug]/(sell)/verify-ticket": [29,[3]],
		"/buy/[showId]": [31,[4]],
		"/buy/[showId]/payment": [~32,[4]],
		"/buy/[showId]/select-seats": [~33,[4]],
		"/buy/[showId]/summary": [~34,[4]],
		"/validate": [35],
		"/[cinemaSlug]": [6,[2]],
		"/[cinemaSlug]/[date]": [7,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,

	reroute: (() => {})
};

export { default as root } from '../root.svelte';